import * as Types from "./DashboardType";
const initialState = {
    primarySalesLoading: false,
    locationLoading: false,
    toCardLoading: false,
    donutLoading: false,
    userInfo: {},
    employeeSearch: [],
    ddlUnit: [],
    dashboardData: null,
    ablData: [],
    targetQty: [],
    deliveryPrice: [],
    secondaryPrice: [],
    Primaryachivement: 0,
    Secondaryachivement: 0,
    ablprice: [],
    ablpricepercentage: [],
    abldate: [],
    currDays: 0,
    ttldays: 0,
    ddcategorytype: [],
    ddproducttype: [],
    cumulativeRev: [],
    anualRev: [],
    ddnsmtype: [],
    dddsmtype: [],
    ddrmtype: [],
    ddtsmtype: [],
    anualablprice: [],
    anualabldate: [],
    anualabldata: [],
    anualgrowth: [],
    cumulativeMonth: [],
    linetype: [],
    cumSecondarydaryDiff: [],
    productGroupType: [],
    TrendValue: [],
    TrendValuePercentage: [],
    damagedata: [],
    damageMonthData: [],
    LocationWisePieData: [],
    zoneData: [],
    zoneDataForDonut: [],
    donutData: [],
    productionLine: [],
    distributorList: [],
    total: 0,
    proLinebyPro: [],
    totalForDonut: 0,
    itemDataInput: {
        ZoneID: null,
        DivisionID: null,
        AreaID: null,
        Territory: null,
        targetyear: null,
        targetMonth: null,
        Fdate: null,
        Tdate: null,
        measurement: null,
    },
    reportData: {
        ZoneID: " ",
        DivisionID: " ",
        AreaID: " ",
        Territory: " ",
        targetyear: " ",
        targetMonth: " ",
        Fdate: " ",
        Tdate: " ",
        measurement: 1,
        categoryName: "",
        lineName: "",
    },
    itemDataInput: {
        itemName: "",
        quantity: "",
        intDistributorId: "",
        distributorName: "",
        submitProductList: [],
    },
    chartData: [],
    chartData2: [],
    locationTotalQty: 0,
    soPoint: [],
    reportDataLoading: false,
    reportDataGroupA: [],
    salesChannel: [],
    distivBankData: 0,
    bankrecLoading: false,
};
const PrimarySalesTrandReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {

        case Types.ABL_PRIMARY_SALES_TRAND:
            // Month To Month Secondary Sales Trend start
            let tredndingIndex = 0;
            let TrendingValueStore = [0];
            if (state.reportData.measurement == 1) {
                tredndingIndex =
                    action.payload.data?.primarySalesList?.length > 0
                        ? action.payload.data?.primarySalesList != undefined
                            ? action.payload.data?.primarySalesList[0]?.cumulativePrice
                            : 0
                        : 0;
            } else {
                tredndingIndex =
                    action.payload.data?.primarySalesList?.length > 0
                        ? action.payload.data?.primarySalesList != undefined
                            ? action.payload.data?.primarySalesList[0].prodQty
                            : 0
                        : 0;
            }

            var ablMonthPriceArray = [];
            var ablMonthPriceArrayPercentage = [0];
            const ablMonthWiseList = action.payload.data?.primarySalesList;

            var trendResult = [0];
            var trendResultPercentage = [0];

            for (let i = 1; i < ablMonthWiseList?.length; i++) {
                ablMonthPriceArray.push(
                    state.reportData.measurement == 1
                        ? ablMonthWiseList[i].prodPrice
                        : ablMonthWiseList[i].prodQty
                );

                ablMonthPriceArrayPercentage.push(
                    parseInt(
                        ((ablMonthPriceArray[i] - ablMonthPriceArray[i - 1]) /
                            ablMonthPriceArray[i - 1]) *
                        100
                    )
                );
            }


            for (
                let i = 1;
                i < action.payload.data?.primarySalesList?.length;
                i++
            ) {
                trendResult.push(
                    (ablMonthPriceArray[i] - ablMonthPriceArray[i - 1]).toFixed(2)
                );

                trendResultPercentage.push(
                    parseInt(
                        ((ablMonthPriceArray[i] - ablMonthPriceArray[i - 1]) /
                            ablMonthPriceArray[i - 1]) *
                        100
                    )
                );
            }

            var ablMonthDateArray = [];
            const monthNames = [
                "",
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];
            const ablMonthList = action.payload.data?.primarySalesList;
            for (let i = 1; i < ablMonthList?.length; i++) {
                ablMonthDateArray.push(monthNames[ablMonthList[i]?.doDate]);
            }


            // Month To Month Secondary Sales Trend end


            return {
                ...state,
                primarySalesLoading: action.payload.isLoading,
                TrendValue: trendResult,
                TrendValuePercentage: trendResultPercentage,
                abldate: ablMonthDateArray,
                ablprice: ablMonthPriceArray,
            };

            case Types.GET_DISTIB_BANK_REC_DATA:
                console.log('action.payload', action.payload)
                return {
                    ...state,
                    bankrecLoading: action.payload.isLoading,
                    distivBankData: action.payload.data?.data,
                    
                };

        default:
            break;
    }
    return newState;
};

export default PrimarySalesTrandReducer;

