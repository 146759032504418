import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  getAblCumulativeSalesReportWithPackType,
  GetABLMOnthWiseData,
  GetCategoryType,
  GetDamageData,
  GetDashboardTargetDate,
  GetDisBankRecvData,
  GetDistributorByTerritoryID,
  GetDSMType,
  GetLineType,
  getLineWiseSalesStandard,
  GetLocationWiseSales,
  GetLocationWiseSalesForDonut,
  GetNSMType,
  GetProductGroup,
  GetProductionLine,
  GetProductionLineByProduction,
  GetProductType,
  GetRMType,
  GetSalesChannelType,
  getSoList,
  GetSoPointByDistributorId,
  GetTSMType,
  handleDateManageForDashboardCart,
  InputHandle,
  InputReportHandle,
} from "./DashboardCartAction";
import {
  getAreaListByDivisionEntry,
  getDivisionListByZoneEntry,
  getSoListByDistributorId,
  getTerritoryEntry,
  getZoneListEntry,
} from "./DistributorReportAction";
import { ToastContainer, toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import Report from "./Report";
import SimpleModal from "../../modules/master/components/Modal/SimpleModal";
import ReportGroupA from "./ReportGroupA";
import { getCumulativeReport } from "./ProductionLineAction";
import { GetablPrimarySalesTrand } from "./PrimarySalesTrandAction";
import { FaArrowRight, FaSearch, FaTable } from "react-icons/fa";

const DashboardInput = () => {
  const [downloadReport, setDownloadReport] = useState(false);
  const [groupAReport, setGroupAReport] = useState(false);

  const [itemifo, setItemifo] = useState(null);
  const conponentPDF = useRef();
  const generatePDF = useReactToPrint({
    // content: () => conponentPDF.current,
    // documentTitle: "Cumulative Report",
    // onAfterPrint: () => alert("Report saved in PDF"),
  });

  const { register, handleSubmit, errors, setFocus, setValue } = useForm();
  const dispatch = useDispatch();
  const reportData = useSelector((state) => state.dashboardcart.reportData);
  const isLoading = useSelector((state) => state.dashboardcart.toCardLoading);
  const categorytype = useSelector(
    (state) => state.dashboardcart.ddcategorytype
  );
  const nsmtype = useSelector((state) => state.dashboardcart.ddnsmtype);
  const dsmtype = useSelector((state) => state.dashboardcart.dddsmtype);
  const rmtype = useSelector((state) => state.dashboardcart.ddrmtype);
  const tsmtype = useSelector((state) => state.dashboardcart.ddtsmtype);
  const product = useSelector((state) => state.dashboardcart.ddproducttype);
  const linetype = useSelector((state) => state.dashboardcart.linetype);
  const salesChannel = useSelector((state) => state.dashboardcart.salesChannel);
  const productGroupType = useSelector(
    (state) => state.dashboardcart.productGroupType
  );
  const productionLine = useSelector(
    (state) => state.dashboardcart.productionLine
  );
  const proLinebyPro = useSelector((state) => state.dashboardcart.proLinebyPro);
  const distributorList = useSelector(
    (state) => state.dashboardcart.distributorList
  );
  const soPoint = useSelector((state) => state.dashboardcart.soPoint);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(0);
  const [area, setArea] = useState([]);
  const [selectDivision, setSelectDivision] = useState(0);
  const [selectArea, setSelectArea] = useState(0);
  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(0);
  const [productlist, SetProductlist] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectCategory, setSelectCategoryType] = useState(0);
  const [selectChannel, setSelectChannelType] = useState(0);
  const [selectProductType, setSelectProductType] = useState(0);
  const [selectcategoryName, setSelectCategoryName] = useState(0);
  const [selectProductGroupType, setSelectProductGroupType] = useState(0);
  const [selectProductionLine, setSelectProductionLine] = useState(0);
  const [soData, setSodata] = useState([]);
  const [selectSo, setSelectSoPoint] = useState(0);
  const [selectDistributorId, setSelectDistributorId] = useState(0);
  const options = [
    { value: "1", label: "Value" },
    { value: "2", label: "Volume" },
  ];
  console.log("selectSo", selectSo);
  const handleClearData = () => {
    // setSelectProductType(null);
    // setSelectProductGroupType(null);
    // setSelectProductionLine(null);
  };

  const handleChangeStartDate = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };

  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);
  };

  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };

  const handleChange = (name, value) => {
    dispatch(InputReportHandle(name, value));
  };

  useEffect(() => {
    initialData();
  }, []);

  // const productList = async () => {
  //     console.log('productList', productList)
  //     let productData = await GetProductType(selectCategory);
  //     console.log('productData', productData)
  //     SetProductlist(productData.data);
  //   }

  const initialData = async () => {
    let d = new Date();
    let firstD =
      d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + 1;
    let currentD =
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2) +
      "";

    let zoneData = await getZoneListEntry();
    // setStartDate(firstD);
    // setToDate(currentD);
    Setzone(zoneData.data);
    dispatch(
      GetDashboardTargetDate(
        selectZone,
        selectDivision,
        selectArea,
        selectTerritory,
        firstD,
        currentD,
        selectCategory,
        selectProductType,
        selectProductGroupType,
        selectProductionLine,
        selectSo,
        selectDistributorId,
        selectChannel
      )
    );
    dispatch(
      GetABLMOnthWiseData(
        selectZone,
        selectDivision,
        selectArea,
        selectTerritory,
        firstD,
        currentD,
        selectCategory,
        selectProductType,
        selectProductGroupType,
        selectProductionLine,
        selectSo,
        selectDistributorId,
        selectChannel
      )
    );
    dispatch(
      GetablPrimarySalesTrand(
        selectZone,
        selectDivision,
        selectArea,
        selectTerritory,
        firstD,
        currentD,
        selectCategory,
        selectProductType,
        selectProductGroupType,
        selectProductionLine,
        selectSo,
        selectDistributorId,
        selectChannel
      )
    );
    dispatch(
      GetDamageData(
        selectZone,
        selectDivision,
        selectArea,
        selectTerritory,
        firstD,
        currentD,
        selectCategory,
        selectProductType,
        selectProductGroupType,
        selectProductionLine,
        selectSo,
        selectDistributorId,
        selectChannel
      )
    );
    // dispatch(GetLocationWiseSales(selectZone, selectDivision, selectArea, selectTerritory, firstD, currentD,selectCategory,selectProductType,selectProductGroupType,selectProductionLine));
    dispatch(
      GetLocationWiseSalesForDonut(
        selectZone,
        selectDivision,
        selectArea,
        selectTerritory,
        firstD,
        currentD,
        selectCategory,
        selectProductType,
        selectProductGroupType,
        selectProductionLine
      )
    );
    dispatch(
      GetDisBankRecvData(
        selectChannel,
        selectZone,
        selectDivision,
        selectArea,
        selectTerritory,
        firstD,
        currentD,
        selectCategory,
        selectProductType,
        selectProductGroupType,
        selectProductionLine
      )
    );
    
    // dispatch(
    //   getLineWiseSalesStandard(
    //     selectZone,
    //     selectDivision,
    //     selectArea,
    //     selectTerritory,
    //     firstD,
    //     currentD,
    //     selectCategory,
    //     selectProductType,
    //     selectProductGroupType,
    //     selectProductionLine
    //   )
    // );
    dispatch(getAblCumulativeSalesReportWithPackType(firstD, currentD));

    dispatch(getCumulativeReport(firstD, currentD));
    // dispatch(GetDistributorByTerritoryID(selectZone, selectDivision, selectArea, selectTerritory))
    dispatch(GetCategoryType());
    dispatch(GetLineType());
    dispatch(GetProductGroup());
    dispatch(GetProductionLine());
    dispatch(GetProductionLineByProduction());
    dispatch(GetSalesChannelType());
  };
  console.log("selectCategory", selectCategory);
  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
    SetDivision(divisionData.data);
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
    setArea(areaData.data);
  };
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id, selectGeoType);
    setTerritory(territoryData.data);
  };
  const soListData = async (id) => {
    dispatch(getSoList(id));
  };
  const soListData2 = async (id) => {
    dispatch(GetSoPointByDistributorId(id));
  };
  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };
  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const soDropDownData = () => {
    let options = [];
    if (soPoint?.length > 0) {
      soPoint.forEach((item) => {
        let itemData = {
          value: item,
          label: item.soName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  console.log("soPoint", soPoint);
  const distributorOption = () => {
    let options = [];
    if (distributorList?.length > 0) {
      distributorList.forEach((item) => {
        let itemData = {
          value: item.distributorId,
          label: item.distributorNmaes,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const handleSoPointId = (id) => {
    const soPointId = soPoint.filter((item) => item);
  };
  console.log("selectTerritory", selectTerritory);
  const onSubmit = async (e) => {};

  const handleShowReport = () => {
    if (startDate && toDate) {
      dispatch(
        GetDashboardTargetDate(
          selectZone,
          selectDivision,
          selectArea,
          selectTerritory,
          startDate,
          toDate,
          selectCategory,
          selectProductType,
          selectProductGroupType,
          selectProductionLine,
          selectSo,
          selectDistributorId,
          selectChannel
        )
      );
      dispatch(
        GetABLMOnthWiseData(
          selectZone,
          selectDivision,
          selectArea,
          selectTerritory,
          startDate,
          toDate,
          selectCategory,
          selectProductType,
          selectProductGroupType,
          selectProductionLine,
          selectSo,
          selectDistributorId,
          selectChannel
        )
      );
      dispatch(
        GetablPrimarySalesTrand(
          selectZone,
          selectDivision,
          selectArea,
          selectTerritory,
          startDate,
          toDate,
          selectCategory,
          selectProductType,
          selectProductGroupType,
          selectProductionLine,
          selectSo,
          selectDistributorId,
          selectChannel
        )
      );
      dispatch(
        GetDamageData(
          selectZone,
          selectDivision,
          selectArea,
          selectTerritory,
          startDate,
          toDate,
          selectCategory,
          selectProductType,
          selectProductGroupType,
          selectProductionLine,
          selectSo,
          selectDistributorId,
          selectChannel
        )
      );
      // dispatch(GetLocationWiseSales(selectZone, selectDivision, selectArea, selectTerritory, startDate, toDate,selectCategory,selectProductType,selectProductGroupType,selectProductionLine));
      dispatch(
        GetLocationWiseSalesForDonut(
          selectZone,
          selectDivision,
          selectArea,
          selectTerritory,
          startDate,
          toDate,
          selectCategory,
          selectProductType,
          selectProductGroupType,
          selectProductionLine
        )
      );
      dispatch(
        GetDisBankRecvData(
          selectChannel,
          selectZone,
          selectDivision,
          selectArea,
          selectTerritory,
          startDate,
          toDate,
          selectCategory,
          selectProductType,
          selectProductGroupType,
          selectProductionLine
        )
      );
    } else {
      alert("Enter From and To Date.");
    }
  };

  const handleAllReport = () => {
    window.open('/allReport', '_blank');
  };



  return (
    <div>
      <div className="card p-3 dashboardCart ">
        <div className="form-group row">
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">From Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="form-control cusheight"
                onChange={handleChangeStartDate}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label"> To Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                value={toDate}
                className="form-control cusheight"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Measurement</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={options} />}
                rules={{ required: false }}
                name="measurement "
                register={register}
                value=""
                isClearable
                onChange={(option) =>
                  handleChange("measurement", option?.value)
                }
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Sales Channel</label>
              <RHFInput
                className="formSelect pt-0 "
                as={<Select options={salesChannel} />}
                rules={{ required: false }}
                name="channelType"
                register={register}
                placeholder="Select Slaes Channel"
                isClearable
                onChange={(option) => {
                  handleChange("channelType", option?.value);
                  handleChange("channelName", option?.label);
                  setSelectChannelType(option?.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zones</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                // value={selectZone}
                value=""
                isClearable
                onChange={(option) => {
                  divisionList(option?.value);
                  setSelectZone(option?.value);
                  dispatch(GetNSMType(option?.value));
                  dispatch(GetDistributorByTerritoryID(option?.value));
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");
                  setValue("distributor", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  dispatch(GetDSMType(option?.value));
                  dispatch(
                    GetDistributorByTerritoryID(selectZone, option?.value)
                  );
                  setValue("areaData", "");
                  setValue("territory", "");
                  setValue("distributor", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2 mt-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Region</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  getTerritoryData(option?.value);
                  setSelectArea(option?.value);
                  dispatch(GetRMType(option?.value));
                  dispatch(
                    GetDistributorByTerritoryID(
                      selectZone,
                      selectDivision,
                      option?.value
                    )
                  );
                  setValue("territory", "");
                  setValue("distributor", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Territory</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={territoryDropdownData()} />}
                rules={{ required: false }}
                name="territory"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  setSelectTerritory(option?.value);
                  soListData(option?.value);
                  dispatch(GetTSMType(option?.value));
                  dispatch(
                    GetDistributorByTerritoryID(
                      selectZone,
                      selectDivision,
                      selectArea,
                      option?.value
                    )
                  );
                  setValue("soData", "");
                  setValue("distributor", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Sales Group</label>
              <RHFInput
                className="formSelect pt-0 "
                as={<Select options={productGroupType} />}
                rules={{ required: false }}
                name="productGroupType"
                register={register}
                placeholder="Select Product Group"
                isClearable
                onChange={(option) => {
                  handleChange("productGroupType", option?.value);
                  handleChange("salesGroupName", option?.label);
                  setSelectProductGroupType(option?.value);
                  setValue("lineType", "");
                  setValue("productType", "");
                  setValue("nsmType", "");
                  setValue("dsmType", "");
                  setValue("rmType", "");
                  setValue("tsmType", "");
                  setValue("productionLine", "");
                  handleClearData();
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          {/* <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Marketing Line</label>
              <RHFInput
                className="formSelect pt-0 "
                as={<Select options={linetype} />}
                rules={{ required: false }}
                name="lineType"
                register={register}
                placeholder="Select Line type"
                isClearable
                onChange={(option) => {
                  handleChange("lineType", option?.value);
                  handleChange("lineName", option?.label);
                  setSelectCategoryType(option?.value);
                  dispatch(GetProductType(option?.value, startDate, toDate));
                  setValue("productType", "");
                  setValue("nsmType", "");
                  setValue("dsmType", "");
                  setValue("rmType", "");
                  setValue("tsmType", "");
                  setValue("productionLine", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div> */}
         
          {/* <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Category</label>
              <RHFInput
                className="formSelect pt-0 "
                as={<Select options={categorytype} />}
                rules={{ required: false }}
                name="categoryType"
                register={register}
                placeholder="Select Category type"
                isClearable
                onChange={(option) => {
                  handleChange("categoryType", option?.value);
                  handleChange("categoryName", option?.label);
                  setSelectCategoryType(option?.value);
                  dispatch(GetProductType(option?.value, startDate, toDate));
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div> */}
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Production Line</label>
              <RHFInput
                className="formSelect pt-0 "
                as={<Select options={productionLine} />}
                rules={{ required: false }}
                name="productionLine"
                register={register}
                placeholder="Select Production Line"
                isClearable
                onChange={(option) => {
                  handleChange("productionLine", option?.value);
                  setSelectProductionLine(option?.value);
                  dispatch(
                    GetProductionLineByProduction(
                      option?.value,
                      startDate,
                      toDate
                    )
                  );
                  setValue("productType", "");
                  setValue("nsmType", "");
                  setValue("dsmType", "");
                  setValue("rmType", "");
                  setValue("tsmType", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">SKU</label>
              <RHFInput
                className="formSelect pt-0 "
                as={<Select options={proLinebyPro} />}
                rules={{ required: false }}
                name="productType"
                register={register}
                placeholder="Select Product type"
                isClearable
                onChange={(option) => {
                  handleChange("productType", option?.value);
                  setSelectProductType(option?.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">NSM</label>
              <RHFInput
                className="formSelect pt-0 "
                as={<Select options={nsmtype} />}
                rules={{ required: false }}
                name="nsmType"
                register={register}
                placeholder="Select NSM type"
                isClearable
                onChange={(option) => {
                  handleChange("nsmType", option?.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">DSM</label>
              <RHFInput
                className="formSelect pt-0 "
                as={<Select options={dsmtype} />}
                rules={{ required: false }}
                name="dsmType"
                register={register}
                placeholder="Select DSM type"
                isClearable
                onChange={(option) => {
                  handleChange("dsmType", option?.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">RM</label>
              <RHFInput
                className="formSelect pt-0 "
                as={<Select options={rmtype} />}
                rules={{ required: false }}
                name="rmType"
                register={register}
                placeholder="Select RM type"
                isClearable
                onChange={(option) => {
                  handleChange("rmType", option?.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">TSM</label>
              <RHFInput
                className="formSelect pt-0 "
                as={<Select options={tsmtype} />}
                rules={{ required: false }}
                name="tsmType"
                register={register}
                placeholder="Select TSM type"
                isClearable
                onChange={(option) => {
                  handleChange("tsmType", option?.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-4">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Distributor</label>
              <RHFInput
                className="formSelect pt-0 "
                as={<Select options={distributorOption()} />}
                rules={{ required: false }}
                name="distributor"
                register={register}
                placeholder="Select Distributor"
                isClearable
                onChange={(option) => {
                  soListData2(option?.value);
                  setSelectDistributorId(option?.value);
                  handleChange("distributor", option?.value);
                  setValue("soData", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-4">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">SO</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={soDropDownData()} />}
                rules={{ required: false }}
                name="soData"
                register={register}
                isClearable
                onChange={(option) => {
                  setSelectSoPoint(option?.value?.sopointid);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          <div className="col-lg-3">
          <Button className="mt-6" size="sm" onClick={() => handleShowReport()}><FaSearch /> Show Report</Button>

          <Button className="mt-6 ml-3" size="sm" onClick={handleAllReport}><FaTable /> All Report <FaArrowRight /></Button>

          
          
            
            {/* <button
              type="button"
              class="btn btn-info btn-sm mt-5"
              onClick={() => setDownloadReport(true)}
            >
              Report
            </button>
            <button
              type="button"
              class="btn bgcolor btn-sm mt-5"
              onClick={() => setGroupAReport(true)}
            >
              Group-A
            </button> */}
          </div>
        </div>
      </div>
      <SimpleModal
        size="xl"
        show={downloadReport}
        handleClose={() => setDownloadReport(false)}
        handleShow={() => setDownloadReport(true)}
      >
        <Report
          handleClose={() => setDownloadReport(false)}
          itemDetailnIfo={itemifo}
        />
      </SimpleModal>
      <SimpleModal
        size="xl"
        show={groupAReport}
        handleClose={() => setGroupAReport(false)}
        handleShow={() => setGroupAReport(true)}
        className={groupAReport ? "full-page-modal" : ""}
      >
        <ReportGroupA
          handleClose={() => setGroupAReport(false)}
          itemDetailnIfo={itemifo}
        />
      </SimpleModal>
    </div>
  );
};

export default DashboardInput;
