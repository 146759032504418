import React from "react";
import { useSelector } from "react-redux";
import LoadingSpinner from "../Billing/Components/LoadingSpinner";

export const numberFormat = (number) => {
  if (number == null) {
    return 0
  }
  else {
    const value = number.toString();
    const formattedValue = value.replace(/(\d)(?=(\d{2})+\d$)/g, "$1,");
    return formattedValue
  }
};

const Money = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  const dashboardInfo = useSelector(
    (state) => state.dashboardcart.dashboardData
  );
  console.log('efffdashboardInfo', dashboardInfo)
  const reportData = useSelector((state) => state.dashboardcart.reportData);
  const Measurement = reportData.measurement;
  const isLoading = useSelector((state) => state.dashboardcart.toCardLoading);
  const remaining = dashboardInfo?.totalDays - dashboardInfo?.currentDays;
  const workingPassed = parseInt(
    ((dashboardInfo?.totalDays - remaining) / dashboardInfo?.totalDays) * 100
  );
  console.log('numberFormat(10000)', numberFormat(null))

  const distivBankData = useSelector((state) => state.primarysalestrand.distivBankData);
  const bankrecLoading = useSelector((state) => state.primarysalestrand.bankrecLoading);
  console.log('distivBankData :>> ', distivBankData);

  const restavgData = Math.floor(
    (dashboardInfo?.totalPrimaryTargetValue -
      dashboardInfo?.totalPrimaryValue) /
    remaining
  );
  const restavgqty = Math.floor(
    (dashboardInfo?.targetPrimaryQty - dashboardInfo?.totalDeliveryQty) /
    remaining
  );

  let nf = new Intl.NumberFormat("en-BD");

  var restavg = isNaN(restavgData) ? 0 : restavgData;
  const color = restavgData < 0 ? " #006622" : "rgb(61 61 148)";
  const resttav = restavgData < 0 ? 0 : restavgData;
  // console.log('numberformatter(dashboardInfo?.totalPrimaryTargetValue)', numberFormatter(dashboardInfo?.totalPrimaryTargetValue))
  return (
    <div className="money" style={{ height: isLoading ? '280px' : 'auto' }}>
      <span className="spineercolor mt-5 mb-5">
        {isLoading && <LoadingSpinner />}
      </span>
      <>
        {!isLoading && (
          <>
            <div className="sub_card">
              <span className="moneyheading poppins updatedC p-1">PRIMARY</span>
            </div>

            <div className="sub_card">
              <span>
                <span className="totaltargettext poppins updatedC spanheading">
                  {" "}
                  Total Target<br></br>
                </span>
                <span className="text-1st updatedC">
                  {numberFormat(
                    parseInt(
                      Measurement == 1
                        ? dashboardInfo?.totalPrimaryTargetValue
                        : dashboardInfo?.targetPrimaryQty
                    )
                  )}
                </span>
              </span>
            </div>
            <div className="sub_card">
              {bankrecLoading && <LoadingSpinner />}
              <span className="txtwidth poppins spanheading collColor">Total Collection</span><br></br>
              {!bankrecLoading && (
                <span className="text-2nd collColor">
                  {numberFormat(parseInt(typeof (distivBankData) == "undefined" ? 0 : distivBankData))}
                </span>)}
            </div>
            {/* <div className="sub_card">
              <span className="moneyacheived ">
                <span className="txtwidth poppins spanheading collColor">Primary Sales</span><br></br>
                <span className="text-2nd collColor">
                  {numberFormat(
                    parseInt(
                      Measurement == 1
                        ? dashboardInfo?.totalPrimaryValue
                        : dashboardInfo?.totalPrimaryQty
                    )
                  )}
                </span>
              </span>
            </div> */}
            <div className="sub_card">
              <span className="moneyacheived ">
                <span className="txtwidth poppins moneyacheivedC spanheading">Primary Sales</span><br></br>
                <span className="text-4th moneyacheivedC">
                  {numberFormat(
                    parseInt(
                      Measurement == 1
                        ? dashboardInfo?.totalPrimaryValue
                        : dashboardInfo?.totalPrimaryQty
                    )
                  )}
                </span>
              </span>
            </div>
            {/* <div className="sub_card">
              <span className="moneyacheived ">
                <span className="txtwidth poppins moneyacheivedC spanheading">Achieved</span><br></br>
                <span className="text-4th moneyacheivedC">
                  {numberFormat(
                    parseInt(
                      Measurement == 1
                        ? dashboardInfo?.totalDeliveryValue
                        : dashboardInfo?.totalDeliveryQty
                    )
                  )}
                </span>
              </span>
            </div> */}
            <div className="sub_card">
              <span className="txtwidth rdtcolor poppins updaterdtColor spanheading">RDT</span><br></br>
              <span className="text-2nd updaterdtColor">
                {year == dashboardInfo?.currentYear &&
                  month == dashboardInfo?.currentMonth ? (
                  <div className="">
                    <span className="text-2nd">
                      {Measurement == 1 ? (
                        <div>{numberFormat(resttav)}</div>
                      ) : (
                        <div>{numberFormat(restavgqty)}</div>
                      )}
                    </span>
                  </div>
                ) : (
                  <span className="text-2nd">0</span>
                )}
              </span>
            </div>
            <div className="sub_card">
              <span className="moneyacheived ">
                <span className="txtwidth poppins moneyacheivedC spanheading">Delivery:{" "}
                {numberFormat(
                    parseInt(
                      Measurement == 1
                        ? dashboardInfo?.totalDeliveryValue
                        : dashboardInfo?.totalDeliveryQty
                    )
                  )}
                
                </span><br></br>
                <span className="text-4th moneyacheivedC">
                  
                </span>
              </span>
            </div>


            {year == dashboardInfo?.currentYear &&
              month == dashboardInfo?.currentMonth ? (
              <div className="sub_card ">
                {/* <h2 className="text-3rd">{remaining} Days Left</h2> */}
                <h2 className="text-5th">{remaining} W.Days Left</h2>
                {/* <span>Time Remaining in current month</span> */}
              </div>
            ) : (
              <h2 className="text-5th p-2">0 W.Days Left</h2>
            )}
            {year == dashboardInfo?.currentYear &&
              month == dashboardInfo?.currentMonth ? (
              <div className="sub_card">
                {/* <h2 className="text-3rd">{remaining} Days Left</h2> */}
                <h2 className="wpassed2">{workingPassed}% T.Passed</h2>
                {/* <span>Time Remaining in current month</span> */}
              </div>
            ) : (
              <h2 className="wpassed2">100% T.Passed</h2>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default Money;
